<template>
  <div class="detail">
    <div class="usertop">
      <p>
        <span class="userspan" @click="backequip">
          <i class="el-icon-arrow-left"></i>设备管理
        </span>
        /设备详情
      </p>
      <div class="equipright">
        <p>{{ this.timeList.date }}</p>
        <p>{{ this.timeList.week }}</p>
      </div>
    </div>
    <div class="equipdetail">
      <div class="equipleft">
        <p>
          设备名称 <span>{{ tablist.device }}</span>
        </p>
        <p>
          设备位置 <span>{{ tablist.address }}</span>
        </p>
      </div>
      <div class="equipright">
        <el-button type="info" round class="retbtn" @click="retall">重启</el-button>
        <!-- <button class="retbtn">重启</button>   -->
      </div>
    </div>
    <div class="fourbox">
      <div class="forbox">
        <img src="../../assets/chongqi.png" alt="" />
        <div class="fbox">
          <div>运行次数</div>
          <div>{{ tablist.zcq }}</div>
          <div>
            今日运行次数：<span>{{ tablist.jrcq }}</span>
          </div>
        </div>
      </div>
      <div class="forbox">
        <img src="../../assets/jiance.png" alt="" />
        <div class="fbox">
          <div>本次已检测数量</div>
          <div>{{ tablist.bcyjc }}</div>
        </div>
      </div>
      <div class="forbox">
        <img src="../../assets/moshi.png" alt="" />
        <div class="fbox">
          <div>模式</div>
          <div v-if="tablist.ms == 1">计数模式</div>
          <div v-if="tablist.ms == 2">单次模式</div>
          <div v-if="tablist.ms == 3">定时模式</div>
        </div>
      </div>
      <div class="forbox">
        <img src="../../assets/dangqian.png" alt="" />
        <div class="fbox">
          <div>当前使用</div>
          <div v-if="tablist.bxz == 1">泵1</div>
          <div v-if="tablist.bxz == 2">泵2</div>
          <!-- <div v-if="tablist.bstate == 1">泵1</div>
          <div v-if="tablist.bstates == 1">泵2</div> -->
        </div>
      </div>
      <div class="forbox">
        <img src="../../assets/zhuangtai.png" alt="" />
        <div class="fbox">
          <div>当前状态</div>
          <!-- <div v-if="tablist.bstate == 1">
            <div v-if="tablist.bstate == 0">停止</div>
            <div v-if="tablist.bstate == 1">运行</div>
            <div v-if="tablist.bstate == 2">故障</div>
          </div>
          <div v-if="tablist.bstates == 1">
            <div v-if="tablist.bstates == 0">停止</div>
            <div v-if="tablist.bstates == 1">运行</div>
            <div v-if="tablist.bstates == 2">故障</div>
          </div> -->
          <div v-if="tablist.bxz == 1">
            <div v-if="tablist.bstate == 0">停止</div>
            <div v-if="tablist.bstate == 1">运行</div>
            <div v-if="tablist.bstate == 2">故障</div>
          </div>
          <div v-if="tablist.bxz == 2">
            <div v-if="tablist.bstates == 0">停止</div>
            <div v-if="tablist.bstates == 1">运行</div>
            <div v-if="tablist.bstates == 2">故障</div>
          </div>
        </div>
      </div>
    </div>
    <div class="taphuan">
      <el-tabs v-model="activeName">
        <el-tab-pane label="泵余量" name="first">
          <remaining></remaining>
        </el-tab-pane>
        <el-tab-pane label="使用时间" name="second">
          <usetime :id="id"></usetime>
        </el-tab-pane>
        <el-tab-pane label="定时启动设置" name="third">
          <settime></settime>
        </el-tab-pane>
        <el-tab-pane label="常用设置" name="fourth">
          <usual></usual>
        </el-tab-pane>
        <el-tab-pane label="报警设置" name="five">
          <warning></warning>
        </el-tab-pane>
        <!-- <el-tab-pane label="初始设置" name="six">
          <initial></initial>   
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import remaining from "../../components/equipment/remaining.vue";
import usetime from "../../components/equipment/usetime.vue";
import settime from "../../components/equipment/settime.vue";
import usual from "../../components/equipment/usual.vue";
import warning from "../../components/equipment/warning.vue";
// import initial from "../../components/equipment/initial.vue";
import { details } from "../../api/detail/details";
import { restart } from "../../api/equip/device";
export default {
  components: { remaining, usetime, settime, usual, warning },
  data() {
    return {
      time: "",
      week: "",
      activeName: "first",
      roe: "",
      day: "",
      id: "",
      tablist: {},
      sn: "",
      timeList: {},
      timer:null
    };
  },
  created() {
    this.setNowTimes();
    console.log(this.day);
    // 传递过来的设备信息

    this.id = this.$store.state.sheid;
    console.log(this.$store.state)
    // this.id = this.$route.query.detailmessage
    console.log(this.id);
    // 权限
    // details(this.id).then((res) => {
    //   console.log(res);
    //   if (res.message == "成功") {
    //     console.log(1213)
    //     // console.log("查询成功");
    //     this.sn = res.details[0].sn;
    //     this.tablist = res.details[0];
    //     this.timeList = res.date[0]
    //     console.log(this.tablist,'121212');
    //   }
    // });
  },
  mounted(){
    this.getDetails()
    this.$nextTick(() => {
      //  定时刷新
      this.timer = setInterval(() => {
        this.getDetails()
      }, 2000)
    })
  },
  methods: {
    // 获取数据
    getDetails() {
      details(this.id).then((res) => {
      console.log(res);
      if (res.message == "成功") {
        console.log(1213)
        // console.log("查询成功");
        this.sn = res.details[0].sn;
        this.tablist = res.details[0];
        this.timeList = res.date[0]
        console.log(this.tablist,'121212');
      }
    });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 想起日期
    setNowTimes() {
      let myDate = new Date();
      // console.log(myDate)
      let wk = myDate.getDay();
      let yy = String(myDate.getFullYear());
      let mm = myDate.getMonth() + 1;
      let dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = weeks[wk];
      // console.log(yy + "年" + mm + "月" + dd + "日");
      // console.log(week);
      // this.nowDate = yy + "-" + mm + "-" + dd;
      // this.nowTime = hou + ":" + min + ":" + sec;
      // this.nowWeek = week;
      let month = "";
      if (mm < 10) {
        month = "0" + mm;
      } else {
        month = mm;
      }
      this.day = yy + month + dd;
      this.time = yy + "年" + mm + "月" + dd + "日";
      this.week = week;
    },
    // 重启
    retall() {
      console.log("重新启动");
      this.$confirm("此操作将重启设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          restart(this.sn).then((res) => {
            console.log(res);
            if (res.resutlt == 0) {
              this.$message.success("重启成功");
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重启",
          });
        });
    },
    // 返回设备
    backequip() {
      this.$router.go(-1)
      //this.$router.push("/equip");
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>


<style lang='less'>
* {
  box-sizing: border-box;
}

.detail {
  padding: 20px;

  .usertop {
    .userspan {
      cursor: pointer;
    }

    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;

    .equipright {
      display: flex;

      p {
        margin-left: 25px;
      }
    }
  }

  .equipdetail {
    padding-left: 30px;
    height: 150px;
    margin-top: 20px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .equipleft,
    .equipright {
      p {
        font-size: 28px;
        font-weight: 600;
        font-family: SourceHanSansSC-bold;

        span {
          color: rgba(51, 51, 51, 100);
          font-size: 24px;
          text-align: left;
          font-weight: 400;
        }
      }

      p:nth-of-type(2) {
        font-size: 20px;
        font-weight: 400;
        line-height: 16px;
        margin-top: -15px;
        color: rgba(162, 162, 162, 100);

        span {
          color: rgba(162, 162, 162, 100);
        }
      }
    }

    .equipright {
      display: flex;
      align-items: center;
      margin-right: 20px;

      // .retbtn {
      //   width: 100px;
      //   height: 60px;
      //   font-size: 20px;
      //   margin-left: 15px;
      // }
      .retbtn {
        width: 100px;
        height: 60px;
        font-size: 20px;
        margin-left: 15px;
        background-color: rgb(86, 119, 34)
      }
    }
  }

  .fourbox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .forbox {
      width: 18%;
      height: 160px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
      }

      font-size: 24px;

      .fbox {
        div:nth-of-type(1) {
          font-weight: 600;
          margin-top: 10px;
        }
        div:nth-of-type(2) {
          font-weight: 600;
          margin-top: 10px;
        }
        div:nth-of-type(3) {
          font-size: 18px;
        }
       // div:nth-of-type(2) {
        //  font-weight: 600;
       //   margin-top: 10px;
        //}

        // div:nth-of-type(3) {
        //   font-size: 18px;
        // }

      }
    }
  }

  .taphuan {
    margin-top: 40px;
  }

  .el-tabs__item {
    font-size: 24px;
  }

  .el-tabs__content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}</style>
